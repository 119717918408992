<template>
  <div id="page">
    <v-row>
      <v-col cols="12" v-if="!preloader">
        <h2 class="my-3">Kalender Einträge</h2>
        <v-btn dark color="primary" class="mb-3" @click="addItem">
          <v-icon>mdi-plus</v-icon>
          Eintrag hinzufügen
        </v-btn>
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :disableSearch="true"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <CreateCalendarItem
      v-if="!preloader"
      :trigger="createItem"
      :calendarId="calendarId"
      :key="componentKey"
      @closeModal="
        createItem = false;
        componentKey++;
      "
      @emit="emittedAction"
    />

    <UpdateCalendarItem
      v-if="editItem && itemData"
      :trigger="editItem"
      :itemData="itemData"
      :itemId="itemId"
      @closeModal="
        editItem = false;
        componentKey++;
      "
    ></UpdateCalendarItem>
  </div>
</template>

<script>
import DataTable from "@/components/ui/DataTable.vue";
import CreateCalendarItem from "@/components/addons/bonus_calendar/CreateCalendarItem.vue";
import UpdateCalendarItem from "@/components/addons/bonus_calendar/UpdateCalendarItem.vue";
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "CalendarItems",
  components: {
    DataTable,
    CreateCalendarItem,
    UpdateCalendarItem,
  },
  props: ["calendarId"],
  data() {
    return {
      title: "",
      preloader: false,
      itemId: "",
      itemIndex: "",
      itemData: "",
      editItem: false,
      createItem: false,
      componentKey: 0,
      items: [],
      headers: [
        { text: "ID", value: "id", sortable: false, width: "20px" },
        {
          text: "Startdatum",
          value: "startDate",
          sortable: false,
          width: "150px",
        },
        { text: "Titel", value: "title", sortable: false },

        {
          text: "",
          value: "actions",
          sortable: false,
          align: "right",
          width: "50px",
        },
      ],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    getItemDataById(id) {
      return this.items.find((item) => item.id === id);
    },

    async getData() {
      // /companies/{id}/addons/eventcalendar/events/{id}/timeslots
      this.preloader = true;
      let response = await this.getRequest(
        "bonusCalendar/" + this.calendarId + "/items"
      );
      this.items = response.data.data;
      this.preloader = false;
    },
    emittedAction(value) {
      if (value.action === "editItem") {
        this.itemId = value.itemId;
        this.itemIndex = value.itemIndex;
        this.itemData = this.getItemDataById(value.itemId);
        this.editItem = true;
      } else if (value.action === "createdItem") {
        this.items.push(value.newPost);
        this.createItem = false;
        this.componentKey++;
      } else if (value.action === "deleteItem") {
        this.deletePrompt(value.itemId); // reload
      } else if (value.action === "closeModal") {
        this.createItem = false;
        this.editItem = false;
        this.componentKey++;
      } else if (value.action === "itemPatched") {
        this.getData(); // reload
        this.componentKey++;
      }
    },
    addItem() {
      this.createItem = true;
    },
    async deletePrompt(id) {
      const userChoice = await this.$dialog.confirm({
        text: "Soll das Event wirklich gelöscht werden?",
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // okay
        /* USEING REUSEABLE MIXIN METHOD */
        this.preloader = true;
        let response = await this.deleteRequest(
          "bonusCalendar/" + this.calendarId + "/items/" + id
        ); // await data from mixin
        this.preloader = false;

        if (response) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Eintrag wurde gelöscht";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
          this.getData(); // reload
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  mixins: [apiRequest],
};
</script>

<style>
.note p {
  margin-bottom: 0 !important;
}
</style>
